import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import getSlot from '../utils/getSlot.js';
import sanitizeEmpty from '../utils/sanitizeEmpty.js';
import dateHelper from '../utils/dateHelper.js';
import { periodic, oddsObj } from '../utils/staticData.js';

import { useSelector, useDispatch } from 'react-redux';
import { updateNote } from '../features/note/noteSlice.js';


import { useMediaQuery } from '@chakra-ui/react';
import { Grid, GridItem, Flex, Spacer } from '@chakra-ui/react';
import { FormControl, FormLabel, Input, Button, Select, Checkbox, Box, Tooltip } from '@chakra-ui/react';
import { QuestionOutlineIcon } from '@chakra-ui/icons';

import Statistics from "statistics.js";

import "react-datepicker/dist/react-datepicker.css";

import RollSnapshot from '../components/RollSnapshot.js';
import RateupMenu from '../components/RateupMenu.js';
import CalcFooter from '../components/CalcFooter.js';
import NewSnapshot from "../components/NewSnapshot";

const SummonCalc = () => {

  // Redux components
  // -----------
  const currentNote = useSelector((state) => state.note.activeNote);
  const dispatch = useDispatch();
  // -----------

  const [loginData, setLoginData] = useState({});

  const [currency, setCurrency] = useState({
    sqPurchase: '',
    purchasePeriod: 0,
    extraPurchases: 0,
    sqStarting: '',
    txStarting: '',
    sqIncome: '',
    txIncome: '',
    sqEvent: '',
    txEvent: '',
    sqExtra: '',
    txExtra: '',
    sqMinus: '',
    txMinus: '',
    dailySingles: '',
    currencyOverride: ''
  });

  const [dateData, setDateData] = useState({
    start: dateHelper(new Date().toLocaleDateString()),
    end: dateHelper(new Date().toLocaleDateString())
  });

  const [sums, setSums] = useState({
    sqSum: 0,
    txSum: 0,
    totalSummons: 0,
  });

  const [summonStats, setSummonStats] = useState({
    targetNo: '',
    targetName: '',
    targetImage: 'https://static.atlasacademy.io/JP/Faces/f_8001000.png',
    rarity: 'ssr',
    numRateup: 1,
    prob: 0.008,
    desired: 1,
    summonOdds: 0,
    summonNotes: '',
    priority: 0,
    slot: '',
    draft: false
  });

  const [elementState, setElementState] = useState({ odds: false });

  const [editState, setEditState] = useState(false);

  const [editingDate, setEditingDate] = useState(false);

  const message = `  Hello, and welcome to Garden of Avalon! Thank you for trying my roll calculator app – I hope it's helpful.
  
  This is still a beta, so please contact me at bmartin2009@gmail.com or github.com/the-wake if you have any bug reports, comments, or suggestions. Thank you very much, and happy summoning!`

  useEffect(() => {
    console.log(message);
  }, []);

  const [savedRolls, setSavedRolls] = useState(JSON.parse(localStorage.getItem('saved-rolls')) || []);

  const style = {
    flexContainer: {
      display: 'flex',
      flexDirection: 'row'
    },
    formEl: {
      flex: '2 3 300px'
    },
    listEl: {
      flex: '3 2 400px',
      background: '#dae8ed',
      borderRadius: '9px',
      overflowY: 'auto',
      maxHeight: '1048px'
    },
  };

  // single media query with no options
  // const [isLargerThan800] = useMediaQuery('(min-width: 800px)')

  // ssr-friendly media query with fallback
  const [isLargerThan1680] = useMediaQuery('(min-width: 1680px)', {
    ssr: true,
    fallback: false, // return false on the server, and re-evaluate on the client side
  });

  const today = dateHelper(new Date().toLocaleDateString());

  // Update currency and calendar state from local storage on component render. (Could refactor into using a redux store.)
  useEffect(() => {
    if (localStorage.getItem('currency')) {
      let localCurrency = JSON.parse(localStorage.getItem('currency'));
      setCurrency(localCurrency);
    };

    if (localStorage.getItem('calendar-data')) {
      let { start, end } = JSON.parse(localStorage.getItem('calendar-data'));
      setDateData({ start, end });
    } else {
      setDateData({ start: today, end: today });
    };
  }, []);

  // Treats and sets login data and currency on page load.
  useEffect(() => {
    let total = JSON.parse(localStorage.getItem('login-data'))?.total || 0;
    let streak = JSON.parse(localStorage.getItem('login-data'))?.streak || 0;
    let date = JSON.parse(localStorage.getItem('login-data'))?.date || today;
    let dateClone = date;
    // console.log(total, streak, dateClone, today);

    const difference = Math.floor(dayjs().diff(dateClone, 'days', true));

    if (difference >= 1) {
      console.log(`Difference: ${difference}`);

      // If it's been at least a week since last login, loop through difference to look for Master Mission refreshes in the elapsed duration.
      if (difference >= 7) {
        const updateWeeklies = window.confirm('It\'s been over a week since last login. Update with Master Missions and daily logins?')

        if (updateWeeklies) {
          const masterMissionGains = calcMasterMissions(dateClone, difference);
          let sqNum = parseInt(currency.sqStarting) || 0;
          setCurrency({ sqStarting: sqNum += parseInt(masterMissionGains) || 0 });
          // console.log(`Added ${masterMissionGains} SQ from Master Missions to ${sqNum} starting SQ.`);
        };
      };

      const monthsDiff = ((dayjs().$y - dayjs(dateClone).$y) * 12) + (dayjs().$M - dayjs(dateClone).$M);
      if (monthsDiff >= 1) {
        const shopUpdate = window.confirm(`${monthsDiff} months have elapsed since last update. Should we add monthly shop tickets to your reserves?`);
        if (shopUpdate) {
          let { sqStarting, txStarting } = JSON.parse(localStorage.getItem('currency'));
          console.log(sqStarting, txStarting);
          txStarting += monthsDiff * 5;
          setCurrency({ sqStarting, txStarting });
          console.log(`Updated reserves with ${monthsDiff * 5} summoning tickets. Now have ${currency.txStarting} summoning tickets`);
        };
      };

      total += difference;
      streak += difference;
      dateClone = today;
      console.log(`Updating login history to ${today}: ${total} total logins, ${streak} login streak.`);
    };

    // Update currency with new login rewards.
    setLoginData({
      total: total || 0,
      streak: streak || 0,
      date: dateClone || today
    });
  }, []);

  // TODO: We could make a treatment function that just loops through two arguments' year / month / day fields, but since different functions do different things with the data it may be too convoluted.

  // Calculates master missions. Could rename to calcStreak instead to be clearer what data it cares about and produces.
  const calcWeeklies = (start, numDays, origin = 0) => {

    // The weekly index of the start day.
    let index;
    // console.log(origin || numDays);

    origin <= 6 ? index = origin : index = (loginData.streak + numDays) % 7;

    let weeklyGains = {
      sq: 0,
      tx: 0
    };

    const masterMissionGains = calcMasterMissions(start, numDays);
    weeklyGains.sq += masterMissionGains;

    // Daily login bonus courser.
    for (let i = 0; i < numDays; i++) {
      const trueI = (i + index) % 7;

      const thisLogin = periodic.weeklyLogin[trueI];

      // Course through weeklyGains to find matching rewards.
      if (thisLogin.type in weeklyGains) {
        weeklyGains[thisLogin.type] += thisLogin.val;
      };
    };
    return weeklyGains;
  };

  const calcMasterMissions = (start, difference) => {
    let dayInc = 0;
    let sqGains = 0;
    start = dayjs(start);
    // console.log(start, difference);

    for (var i = 0; i < difference; i++) {
      dayInc++;
      const currentDay = start.add(dayInc, 'day').format('ddd');
      if (currentDay === "Sun") {
        sqGains += 3;
        // console.log(`Adding 3 SQ to total`)
      };
    };
    return sqGains;
  };

  // Calculates login streaks.
  const calcLogins = (start, end) => {
    const { total } = loginData;
    // console.log(loginData);
    const startIndex = total % 50;
    // console.log(startIndex);

    // Changed from ceil to floor.
    let distance = Math.floor(end.diff(start, 'days', true));

    const endingLogins = startIndex + distance;
    const loginSQ = Math.floor(endingLogins / 50) * 30;

    // console.log(`${distance} days until end date. Total logins gain will be ${loginSQ} Quartz.`);

    return loginSQ;
  };

  // Calculates standard MP shop (not special MP shop items; those go in calcEvents).
  const calcShop = (monthDistance) => {
    const shopTx = monthDistance * 5;
    return shopTx;
  };

  // Calculates events.
  const calcEvents = () => {

    const totalEvents = {
      sq: 0,
      tx: 0
    };

    return totalEvents;
  };

  // Calculates purchases.
  const calcPurchases = (numMonths) => {
    let { sqPurchase, purchasePeriod, extraPurchases } = currency;

    let numPurchases = parseInt(purchasePeriod) === 0 ? 1 : numMonths + 1 + parseInt(extraPurchases);

    parseInt(numPurchases) < 0 ? numPurchases = 0 : numPurchases = parseInt(numPurchases);
    // console.log(sqPurchase, numPurchases);

    const totalPurchases = parseInt(sqPurchase) * numPurchases;
    // console.log(totalPurchases);
    return totalPurchases;
  };

  const calcSums = (overrideVal, sumsObj) => {
    // Currency override toggle to short-circuit individual tabulations.
    if (currency.currencyOverride) {
      const newSummons = totalSummons(sumsObj.sqSum, sumsObj.txSum);
      setSums({ ...sums, totalSummons: newSummons });

      const newOdds = calcOdds(newSummons, summonStats.prob, summonStats.desired);

      setElementState({ ...elementState, odds: true });
      setSummonStats({ ...summonStats, summonOdds: newOdds });
      return;
    };

    const start = dayjs(dateData.start);
    const end = dayjs(dateData.end);

    if (end.diff(start) < 0) {
      return;
    };

    let numDays = Math.ceil(dayjs(end).diff(dayjs(start), 'day', true));
    const numMonths = (end.$y - start.$y) * 12 + (end.$M - start.$M);

    const startingSq = currency.sqStarting || 0
    const startingTx = currency.txStarting || 0;
    const weeklies = calcWeeklies(start, numDays);
    const logins = calcLogins(start, end);
    const shop = calcShop(numMonths);
    const events = calcEvents(start, end);
    const purchases = calcPurchases(numMonths) || 0;
    const eventSq = parseInt(currency.sqEvent) || 0;
    const eventTx = parseInt(currency.txEvent) || 0;
    const otherSq = parseInt(currency.sqExtra) || 0;
    const otherTx = parseInt(currency.txExtra) || 0;
    const spentSq = parseInt(currency.sqMinus) || 0;
    const spentTx = parseInt(currency.txMinus) || 0;
    const dailySpending = parseInt(currency.dailySingles) * numDays || 0;

    const gainedSq = parseInt(weeklies.sq + logins + events.sq);
    const gainedTx = parseInt(weeklies.tx + shop + events.tx);


    const newSq = gainedSq + startingSq + purchases + eventSq + otherSq - spentSq - dailySpending || 0;
    const newTx = gainedTx + startingTx + +eventTx + otherTx - spentTx || 0;

    const newSummons = totalSummons(newSq, newTx);
    setSums({ sqSum: newSq, txSum: newTx, totalSummons: newSummons });

    const newOdds = calcOdds(newSummons, summonStats.prob, summonStats.desired);

    setElementState({ ...elementState, odds: true });
    setSummonStats({ ...summonStats, summonOdds: newOdds });
  };

  const calcOdds = (n, p, k) => {
    // n: total summons
    // p: prob
    // k: desired

    if (!(parseInt(k) > 0)) {
      return;
    };

    if (p > 1) p = 1;

    var stats = new Statistics({
      n,
      p,
      // q: (1 - summonStats.prob),
      k
    });

    const binomial = stats.binomialDistribution(n, p);

    const binomCalc = () => {
      let totalProb = 0;
      for (let i = k; i < binomial.length; i++) {
        // console.log(binomial[i]);
        if (isNaN(binomial[i])) {
          // console.log(totalProb);
          return totalProb;
        } else {
          totalProb += binomial[i];
        };
      };
      return totalProb;
    };

    const totalProb = binomCalc();

    const percentage = parseFloat(totalProb * 100).toFixed(2);

    let oddsRender = `${percentage}%`;

    if (summonStats.rarity === 'ssr' && k === 1 && n >= 330) {
      oddsRender += ` (Pity)`
    };

    return oddsRender;
  };

  const totalSummons = (sq, tx, rolls) => {
    let total = rolls || Math.floor((sq / 3 + tx) + Math.floor((sq / 3 + tx) / 10));

    if (isNaN(total) || total < 0) {
      total = 0;
    };

    return total;
  };

  const handleFormUpdate = (e) => {
    // Chakra checkboxes need you to use onChange hooks to handle their state, so we need to return out if we're targeting a checkbox.
    if (e.target.name === 'currencyOverride') return;
    // Sanitize any ints passed as strings.
    let targetVal = e.target.value;

    if (!isNaN(parseInt(targetVal))) {
      targetVal = parseInt(targetVal);
    };
    // console.log(targetVal);

    // Handle login updates. (Dates are handled separately.)
    if (e.target.name === 'start' || e.target.name === 'end') {
      setDateData({ ...dateData, [e.target.name]: e.target.value });
    } else if (e.target.name === 'total' || e.target.name === 'streak') {
      setLoginData({ ...loginData, [e.target.name]: parseInt(e.target.value) });
    }

    else if (e.target.name === 'extraPurchases') {
      setCurrency({ ...currency, extraPurchases: targetVal })
    }
    else if (e.target.name === 'sqSum' || e.target.name === 'txSum' || e.target.name === 'totalSummons') {
      setSums({ ...sums, [e.target.name]: targetVal });
      e.target.name === 'totalSummons' && setSummonStats({ ...summonStats, summonOdds: calcOdds(targetVal, summonStats.prob, summonStats.desired) });
    }
    // Handle all other updates with proper integer or string.
    else {
      setCurrency({ ...currency, [e.target.name]: targetVal });
    };
  };

  useEffect(() => {
    setElementState({ ...elementState, odds: false });
    const currencyClone = { ...currency };
    // console.log(currencyClone);
    const sanitizedCurrency = sanitizeEmpty(currencyClone);
    localStorage.setItem('currency', JSON.stringify(sanitizedCurrency));
    calcSums(currency.currencyOverride, sums);
  }, [loginData, currency, sums.sqSum, sums.txSum]);

  let dateTimeout;

  // Using the above useEffect when changing dates causes hangups when changing the year field, as the app tries to recalculate income with each keystroke. This separate useEffect handles date updates.
  useEffect(() => {
    dateData && localStorage.setItem('calendar-data', JSON.stringify(dateData));

    setElementState({ ...elementState, odds: false });
    const currencyClone = { ...currency };
    const sanitizedCurrency = sanitizeEmpty(currencyClone);
    localStorage.setItem('currency', JSON.stringify(sanitizedCurrency));

    // console.log(`Editing Date: ${editingDate}`);

    if (editingDate === true) {
      clearTimeout(dateTimeout);
      dateTimeout = setTimeout(() => {
        setEditingDate(false);
        calcSums(currency.currencyOverride, sums);
      }, 500);
    } else {
      clearTimeout(dateTimeout);
      calcSums(currency.currencyOverride, sums);
    };

  }, [editingDate]);

  const dateInputHelper = (e) => {
    let parsedVal = e.target.value.split('-')[0];
    while (parsedVal.charAt(0) === '0') {
      parsedVal = parsedVal.substring(1);
    };
    parsedVal.length === 4 && e.target.blur();
  };

  // Set local storage when updating login streak or currency totals.
  useEffect(() => {
    if (loginData.total || loginData.streak || loginData.date) {
      // console.log('Storing', loginData);
      localStorage.setItem('login-data', JSON.stringify(loginData));
    };
  }, [loginData]);

  useEffect(() => {
    // savedRolls.length > 0 && console.log('Saved Roll Data: ', savedRolls);
    localStorage.setItem('saved-rolls', JSON.stringify(savedRolls));
  }, [savedRolls]);

  const clearForm = () => {
    setCurrency({
      ...currency,
      sqPurchase: '',
      extraPurchases: '',
      sqStarting: '',
      txStarting: '',
      sqIncome: '',
      txIncome: '',
      sqEvent: '',
      txEvent: '',
      sqExtra: '',
      txExtra: '',
      sqMinus: '',
      txMinus: '',
      dailySingles: '',
      currencyOverride: false
    });
  };

  const probHandler = (e) => {
    if (e.target.name === 'rarity') {
      setSummonStats({ ...summonStats, numRateup: 1 });
      const newProb = oddsObj[e.target.value][summonStats.numRateup - 1] || oddsObj[e.target.value][0];
      setSummonStats({ ...summonStats, [e.target.name]: e.target.value, prob: newProb });
    } else if (e.target.name === 'numRateup') {
      if (e.target.value != 0) {
        const newProb = oddsObj[summonStats.rarity][e.target.value - 1];
        setSummonStats({ ...summonStats, [e.target.name]: e.target.value, prob: newProb });
      } else {
        setSummonStats({ ...summonStats, numRateup: 0 });
      }
    } else if (e.target.name === 'prob') {
      let newProb = e.target.value;
      if (parseFloat(newProb) > 1) newProb = 1.00;
      setSummonStats({ ...summonStats, prob: newProb });
    } else if (e.target.name === 'desired') {
      setSummonStats({ ...summonStats, desired: parseInt(e.target.value) });
    };
  };

  const saveSnapshot = () => {
    const savedRoll = {
      ...dateData,
      ...currency,
      ...sums,
      ...summonStats,
    };

    // If making a new entry, generate a slot and save it.
    if (editState === false) {
      savedRoll.slot = getSlot();
      console.log(`Saving`, savedRoll);
      setSavedRolls([...savedRolls, savedRoll]);
    }
    // If editing a roll, find its index and update it.
    else if (editState >= 0) {
      let rollsClone = [...savedRolls];
      console.log(rollsClone);
      const rollIndex = savedRoll.slot;
      console.log(`Updating roll index ${rollIndex}`);
      const updatedRolls = rollsClone.map((roll, i) => {
        if (roll.slot === rollIndex) {
          // console.log(`Matched roll index ${rollIndex}. Returning`, savedRoll);
          return savedRoll;
        } else {
          return roll;
        };
      });
      console.log(updatedRolls);
      setSavedRolls(updatedRolls);
      clearForm();
    };

    setSummonStats({ ...summonStats, targetNo: '', targetName: '', targetImage: 'https://static.atlasacademy.io/JP/Faces/f_8001000.png', summonNotes: '', priority: 0 });
    setEditState(false);
    dispatch(updateNote(''));
  };

  const handleEditCancel = () => {
    setEditState(false)
    setSummonStats({ ...summonStats, targetNo: '', targetName: '', targetImage: 'https://static.atlasacademy.io/JP/Faces/f_8001000.png', summonNotes: '', priority: 0 });
    dispatch(updateNote(''));
  };

  const totalDays = () => {
    const start = dayjs(dateData.start);
    const end = dayjs(dateData.end);
    let range = Math.max(Math.floor(end.diff(start, 'days', true)), -1);
    if (isNaN(range)) range = 0;
    return range;
  };

  const handleBulkUpdate = (sq, tx) => {
    sq = parseInt(sq) || 0;
    tx = parseInt(tx) || 0;
    console.log(sq, tx);

    const newRolls = savedRolls.map((roll, pos) => {
      if (roll.draft) {
        return roll;
      };

      const nSummons = totalSummons(roll.sqSum + sq, roll.txSum + tx);
      const newMath = calcOdds(nSummons, summonStats.prob, summonStats.desired);
      console.log(newMath);
      const updatedObj = { ...roll, sqStarting: roll.sqStarting + sq, txStarting: roll.txStarting + tx, sqSum: roll.sqSum + sq, txSum: roll.txSum + tx, totalSummons: nSummons, summonOdds: newMath };
      return updatedObj;
    });

    console.log(newRolls);
    setSavedRolls(newRolls);
  };

  const rollMap = () => {
    {
      return savedRolls.map((roll, pos) => (
        <GridItem key={`${roll.slot}-${JSON.stringify(roll)}`}>
          <RollSnapshot key={pos}
            rollObj={roll}
            savedRolls={savedRolls} setSavedRolls={setSavedRolls} setDateData={setDateData} setCurrency={setCurrency} setSums={setSums} summonStats={summonStats} setSummonStats={setSummonStats} editState={editState} setEditState={setEditState} rollIndex={roll.slot} calcOdds={calcOdds} noteChangeHandler={noteChangeHandler} noteSubmitHandler={noteSubmitHandler} notesReset={notesReset}
          // noteOverride={noteOverride} setNoteOverride={setNoteOverride}
          />
        </GridItem>
      ));
    };
  };

  const noteChangeHandler = (e) => {
    dispatch(updateNote(e.target.value));
  };

  const noteSubmitHandler = (targetRoll) => {
    // If no target is specified and you're not editing a roll (e.g. you're working on a new/unsaved roll), just set the summonStats' note value and finish.
    if (targetRoll === undefined && editState === false) {
      setSummonStats({ ...summonStats, summonNotes: currentNote });
      console.log(`Setting state to ${currentNote}`);
      return;
    };
    // If neither target nor edit state exists, this won't execute; if either exists, this will make sure we're using the right one.
    const targetIndex = targetRoll !== undefined ? targetRoll : editState;

    const updatedRolls = savedRolls.map((roll, pos) => {
      if (roll.slot === targetIndex) {
        return { ...roll, summonNotes: currentNote };
      } else {
        return roll;
      };
    });

    setSavedRolls(updatedRolls);

    // Catch times when the user is on a blank workspace, but brings up a note from a saved roll.
    targetRoll === editState && setSummonStats({ ...summonStats, summonNotes: currentNote });
  };

  // Runs when closing the modal from a roll snapshot, to see if the note that was closed is different than the one currently in the editor.
  const notesReset = (targetNoteSlot) => {
    if (editState === false && targetNoteSlot !== undefined) {
      dispatch(updateNote(summonStats.summonNotes));
    };

    if (editState !== false && editState !== targetNoteSlot) {
      console.log(`Will return active note to ${summonStats.summonNotes}`);
      dispatch(updateNote(summonStats.summonNotes));
    };
  };

  return (
    <>
      <Flex mt={8} pb='80px' flexDirection={isLargerThan1680 ? 'row' : 'column'}>
        <div style={style.formEl}>
          <FormControl maxW="600px" marginLeft="auto" marginRight="auto" onChange={handleFormUpdate}>
            <Grid h='' templateRows="repeat(1, 1fr)" templateColumns="repeat(2, 1fr)" gap={2}>
              <GridItem rowSpan={1} colSpan={1}>
                <FormLabel>Login Streak:</FormLabel>
                <Input className="form-input" name="streak" type="number" placeholder="0" value={loginData.streak === 0 ? '' : loginData.streak} />
              </GridItem>
              <GridItem rowSpan={1} colSpan={1}>
                <FormLabel>Total Logins:</FormLabel>
                <Input className="form-input" name="total" type="number" placeholder="0" value={loginData.total === 0 ? '' : loginData.total} />
              </GridItem>
              <GridItem rowSpan={1} colSpan={1}>
                <FormLabel>Starting Quartz:</FormLabel>
                <Input className="form-input" name="sqStarting" type="number" placeholder="0" value={currency.sqStarting === 0 ? '' : currency.sqStarting} />
              </GridItem>
              <GridItem rowSpan={1} colSpan={1}>
                <FormLabel>Starting Tickets:</FormLabel>
                <Input className="form-input" name="txStarting" type="number" placeholder="0" value={currency.txStarting === 0 ? '' : currency.txStarting} />
              </GridItem>
              <GridItem rowSpan={1} colSpan={1}>
                <FormLabel>SQ Purchasing:</FormLabel>
                <Input className="form-input" name="sqPurchase" type="number" placeholder="0" value={currency.sqPurchase === 0 ? '' : currency.sqPurchase} />
              </GridItem>
              <GridItem rowSpan={1} colSpan={1}>
                <FormLabel>Frequency:</FormLabel>
                <Select className="form-input" name="purchasePeriod" type="text" value={currency.purchasePeriod}>
                  <option value={0}>One-time</option>
                  <option value={1}>Monthly</option>
                </Select>
              </GridItem>
              <GridItem rowSpan={1} colSpan={1}>
                <FormLabel disabled={currency.purchasePeriod === 0}>Adjust # Purchases (+/-) <Tooltip label="For example, if you've already made this month's purchase, enter -1 to remove it from the calculation."><QuestionOutlineIcon></QuestionOutlineIcon></Tooltip></FormLabel>
                {/* <Checkbox name="alreadyPurchased" disabled={currency.purchasePeriod === 0} checked={currencyHelper} value={currency.alreadyPurchased}>Already purchased first month?</Checkbox> */}
              </GridItem>
              <GridItem rowSpan={1} colSpan={1}>
                <Input className="form-input" name="extraPurchases" type="number" disabled={currency.purchasePeriod === 0} placeholder="0" value={currency.extraPurchases === 0 ? '' : currency.extraPurchases} />
                {/* <Checkbox name="alreadyPurchased" disabled={currency.purchasePeriod === 0} checked={currencyHelper} value={currency.alreadyPurchased}>Already purchased first month?</Checkbox> */}
              </GridItem>
              <GridItem rowSpan={1} colSpan={1}>
                <FormLabel>Event SQ:</FormLabel>
                <Input className="form-input" name="sqEvent" type="number" placeholder="0" value={currency.sqEvent === 0 ? '' : currency.sqEvent} />
              </GridItem>
              <GridItem rowSpan={1} colSpan={1}>
                <FormLabel>Event Tickets:</FormLabel>
                <Input className="form-input" name="txEvent" type="number" placeholder="0" value={currency.txEvent === 0 ? '' : currency.txEvent} />
              </GridItem>
              <GridItem rowSpan={1} colSpan={1}>
                <FormLabel>Extra SQ:</FormLabel>
                <Input className="form-input" name="sqExtra" type="number" placeholder="0" value={currency.sqExtra === 0 ? '' : currency.sqExtra} />
              </GridItem>
              <GridItem rowSpan={1} colSpan={1}>
                <FormLabel>Extra Tickets:</FormLabel>
                <Input className="form-input" name="txExtra" type="number" placeholder="0" value={currency.txExtra === 0 ? '' : currency.txExtra} />
              </GridItem>
              <GridItem rowSpan={1} colSpan={1}>
                <FormLabel>Expected SQ Spending:</FormLabel>
                <Input className="form-input" name="sqMinus" type="number" placeholder="0" value={currency.sqMinus === 0 ? '' : currency.sqMinus} />
              </GridItem>
              <GridItem rowSpan={1} colSpan={1}>
                <FormLabel>Expected Ticket Spending:</FormLabel>
                <Input className="form-input" name="txMinus" type="number" placeholder="0" value={currency.txMinus === 0 ? '' : currency.txMinus} />
              </GridItem>
              <GridItem rowSpan={1} colSpan={1}>
                <FormLabel>Start Date:</FormLabel>
                <Input name="start" type="date" value={dateData.start} max={2100}
                  onChange={(e) => { dateInputHelper(e); setEditingDate(true) }} onBlur={() => { setEditingDate(false) }}
                />
              </GridItem>
              <GridItem rowSpan={1} colSpan={1}>
                <FormLabel>End Date:</FormLabel>
                <Input name="end" type="date" isInvalid={totalDays() < 0} errorBorderColor='crimson' value={dateData.end}
                  onChange={(e) => { dateInputHelper(e); setEditingDate(true) }} onBlur={() => { setEditingDate(false) }}
                />
              </GridItem>
              <GridItem rowSpan={1} colSpan={1}>
                <FormLabel># Daily Singles</FormLabel>
                <Input name="dailySingles" type="number" placeholder="0" value={currency.dailySingles === 0 ? '' : currency.dailySingles} />
              </GridItem>
              <GridItem rowSpan={1} colSpan={1}>
                <FormLabel>Total Days</FormLabel>
                <Input name="dateRange" type="number" disabled={true} isInvalid={totalDays() < 0} errorBorderColor='crimson' placeholder="0" value={totalDays() === 0 ? '' : totalDays()} />
              </GridItem>
            </Grid>
            <Grid mt={6} templateRows="repeat(1, 1fr)" templateColumns="repeat(2, 1fr)" gap={2}>
              <GridItem rowSpan={1} colSpan={1}>
                <FormLabel>Total Quartz:</FormLabel>
                <Input className="form-input" type="number" isReadOnly={!currency.currencyOverride} name="sqSum" value={sums.sqSum} placeholder="0" />
              </GridItem>
              <GridItem rowSpan={1} colSpan={1}>
                <FormLabel>Total Tickets:</FormLabel>
                <Input className="form-input" type="number" isReadOnly={!currency.currencyOverride} name="txSum" value={sums.txSum} placeholder="0" />
              </GridItem>
              <GridItem rowSpan={1} colSpan={2} >
                <FormLabel>Total Summons:</FormLabel>
              </GridItem>
              <GridItem rowSpan={1} colSpan={1} >
                <Input className="form-input" type="number" isReadOnly={!currency.currencyOverride} name="totalSummons" max={10000} value={sums.totalSummons} placeholder="0" />
              </GridItem>
              <GridItem rowSpan={1} colSpan={1}>
                <Checkbox name="currencyOverride" isChecked={currency.currencyOverride} onChange={(e) => { setCurrency({ ...currency, currencyOverride: e.target.checked }) }}>Toggle Manual Currency Entry</Checkbox>
              </GridItem>
            </Grid>
            <Grid templateRows="repeat(1, 1fr)" templateColumns="repeat(2, 1fr)" gap={2}>
              <RateupMenu probHandler={probHandler} summonStats={summonStats} setSummonStats={setSummonStats} oddsObj={oddsObj} />
              <GridItem rowSpan={1} colSpan={1}>
                <FormLabel>Number of Copies Desired:</FormLabel>
                <Input className="form-input" type="number" name="desired" value={summonStats.desired} onChange={probHandler} />
              </GridItem>
              <GridItem className="results-area" rowSpan={1} colSpan={2} margin='auto'>
                <Flex flexDirection='row' justifyContent='space-evenly' gap={4} maxWidth='400px'>
                  <FormLabel textAlign='center' minWidth='120px' margin='auto'>Total Odds:</FormLabel>
                  <Input className="form-input" isReadOnly={true} name="summonOdds" value={summonStats.summonOdds} />
                </Flex>
              </GridItem>
            </Grid>
          </FormControl>
        </div>
        <Box style={style.listEl}>
          {rollMap()}
          <NewSnapshot savedRolls={savedRolls} setSavedRolls={setSavedRolls} />
        </Box>
      </Flex >
      <CalcFooter summonStats={summonStats} setSummonStats={setSummonStats} calcOdds={calcOdds} editState={editState} handleEditCancel={handleEditCancel} handleBulkUpdate={handleBulkUpdate} savedRolls={savedRolls} setSavedRolls={setSavedRolls} saveSnapshot={saveSnapshot} clearForm={clearForm} noteChangeHandler={noteChangeHandler} noteSubmitHandler={noteSubmitHandler} notesReset={notesReset} />
    </>
  )
};

export default SummonCalc;
